import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from '../../services/axios';
import { toast } from 'react-toastify';
import { getUserDetails } from '../../components/utils/functions';
import { URLS } from '../../services/urls';
import Preloader from '../../components/preloader/Preloader';

const Quickmessage = () => {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState({});
  const [userService, setUserService] = useState('');
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate();

  // Questions par catégorie de service
  const questionsByCategory = {
    "Service Commercial": [
      "Contexte : Vous êtes un commercial de GOLIAT, spécialisé dans la vente de containers et de bungalows en Europe. Votre objectif principal est de répondre aux demandes de devis et de négocier des contrats tout en anticipant les besoins des clients. Prompt :Je suis un commercial chez GOLIAT, une entreprise spécialisée dans la vente de containers et bungalows en Europe. Mon objectif est de répondre à une demande d'un client potentiel qui cherche une solution rapide pour équiper un site industriel en France. Le client a besoin de connaître les avantages de nos produits par rapport à la concurrence, le délai de livraison, et des conseils sur l'entretien des installations. Propose un argumentaire commercial clair, avec des chiffres impactants, des exemples d'utilisation, et un ton rassurant mais persuasif. Ajoute des propositions complémentaires pour inciter le client à signer rapidement.",
      "Vous êtes un expert en techniques de vente B2B et B2C, spécialisé dans la vente de containers et bungalows. Proposez-moi des stratégies innovantes pour convaincre un client hésitant sur les avantages de nos produits par rapport à la concurrence.",
      "Rédigez un email commercial accrocheur et professionnel pour présenter à un prospect nos nouvelles offres de containers modulaires, en mettant en avant les avantages de personnalisation, la durabilité et le prix compétitif.",
      "Analysez le profil suivant : un client potentiel dans le secteur de la construction, intéressé par des solutions modulaires pour réduire ses coûts de chantier. Proposez une stratégie commerciale adaptée pour le convertir en client fidèle.",
    ],
    "Service Comptabilité": [
      "Contexte : Vous êtes chargé de gérer les finances et d’assurer la conformité des opérations comptables. Vous traitez des factures, suivez les paiements clients et veillez au respect des échéances. Prompt :Je suis un comptable chez GOLIAT, responsable du suivi des factures et des paiements des clients en Europe. Un client ayant un retard de paiement de 30 jours sur une facture importante me demande un délai supplémentaire. Mon rôle est de rédiger une réponse professionnelle, expliquant les politiques de l’entreprise en matière de paiement et proposant une solution de compromis (comme un échéancier). Assure-toi que le ton reste ferme mais cordial, en insistant sur l’importance de régler rapidement pour éviter des pénalités. Fournis également une vue d’ensemble des impacts financiers possibles pour GOLIAT si ce retard persiste.",
      "Vous êtes un expert comptable avec une spécialisation dans la gestion des retards de paiement. Rédigez un email poli mais ferme pour rappeler à un client le paiement d’une facture en retard, tout en proposant une solution d'échéancier.",
      "Créez un tableau dynamique détaillant les étapes à suivre pour automatiser les relances de paiement des clients dans Odoo, avec des scénarios pour les factures en retard de 7 jours, 15 jours et 30 jours.",
      "Proposez des recommandations pour améliorer le suivi des factures dans une entreprise de taille moyenne utilisant Odoo. Intégrez les aspects de reporting, d’optimisation de la trésorerie et de conformité fiscale."
    ],
    "Service Marketing": [
      "Contexte : Vous êtes chargé de créer des campagnes et de renforcer l'image de marque de GOLIAT pour attirer de nouveaux clients et fidéliser les anciens. Prompt :Je suis responsable marketing chez GOLIAT, une entreprise spécialisée dans les containers et bungalows en Europe. Mon objectif est de concevoir une campagne publicitaire innovante sur les réseaux sociaux pour promouvoir notre nouvelle gamme de containers modulaires écologiques. La campagne doit inclure des messages percutants, des visuels attractifs et des idées de publications interactives (comme des quiz ou des témoignages de clients). Propose un plan détaillé pour cibler à la fois les entreprises industrielles et les particuliers, avec des suggestions d’outils pour mesurer l’engagement et l’impact de la campagne.",
      "Vous êtes un spécialiste en marketing digital. Proposez une campagne publicitaire pour mettre en avant nos containers modulaires sur les réseaux sociaux (Facebook, LinkedIn et Instagram). Incluez des idées de visuels, slogans et messages d'accroche.",
      "Créez un calendrier éditorial détaillé pour le mois à venir, mettant en avant nos produits phares, des témoignages clients, et des publications éducatives sur les avantages de nos solutions modulaires.",
      "Analysez cette situation : nous avons investi dans une campagne Google Ads, mais le taux de clic est élevé tandis que le taux de conversion reste faible. Proposez des ajustements à apporter pour améliorer le retour sur investissement.",
      "Vous êtes un spécialiste en marketing. Comment évaluer le ROI d'une campagne publicitaire?"
    ],
    "Service Informatique": [
      "Contexte : Vous êtes en charge des systèmes informatiques et de l’intégration d’une IA générative dans l’ERP Odoo de GOLIAT. Vous devez optimiser la communication entre l’IA et les utilisateurs. Prompt :Je suis un ingénieur informatique chez GOLIAT, responsable de l’intégration d’une IA générative dans notre ERP Odoo. Mon objectif est de concevoir une interface utilisateur intuitive qui permettra aux employés de poser des questions à l’IA pour automatiser leurs tâches (ex : gestion des stocks, création de rapports). Fournis des recommandations sur les meilleures pratiques UX/UI pour ce type d’application, propose des exemples de flux conversationnels efficaces et des solutions pour garantir la confidentialité des données. Décris également comment l’IA peut apprendre des interactions des utilisateurs pour améliorer ses performances au fil du temps.",
      "Vous êtes un consultant en informatique spécialisé dans la gestion des ERP. Proposez des solutions pour intégrer une fonctionnalité de VoIP dans notre ERP Odoo afin d'améliorer les communications internes et le suivi client.",
      "Rédigez une procédure pas à pas pour automatiser les sauvegardes quotidiennes de notre base de données Odoo sur un serveur VPS OVH. Incluez des recommandations pour la sécurité et la performance.",
      "Proposez des fonctionnalités innovantes à ajouter à notre ERP Odoo pour améliorer l’efficacité des commerciaux et du suivi des clients, en utilisant l’IA générative pour automatiser certaines tâches répétitives.",
      "Quelles sont les bonnes pratiques pour sécuriser un serveur?"
    ],
    "Service Administratif": [
      "Contexte : Vous gérez les ressources humaines, le suivi des performances des employés, et les tâches administratives globales. Prompt :Je suis un employé du service administratif chez GOLIAT, en charge d’organiser une réunion annuelle pour faire le bilan de l’année écoulée et préparer les objectifs de l’année prochaine. Mon objectif est de rédiger un ordre du jour structuré pour cette réunion, qui inclut les réalisations des équipes, les domaines d’amélioration, les objectifs pour chaque service, et un espace pour les questions des employés. Ajoute des idées pour dynamiser la réunion et renforcer la motivation des équipes, comme des moments de reconnaissance ou des récompenses. Propose également des outils numériques pour rendre la réunion interactive et productive.",
      "Vous êtes un assistant administratif virtuel pour une entreprise spécialisée dans la vente de containers. Proposez une procédure pour améliorer la gestion des contrats clients, incluant la signature électronique et le suivi des documents.",
      "Rédigez une note interne pour informer tous les collaborateurs d’une mise à jour importante de notre système ERP, en expliquant les étapes à suivre pour s’adapter aux nouvelles fonctionnalités.",
      "Identifiez les principaux goulots d’étranglement dans les processus administratifs d’une entreprise de taille moyenne et proposez des solutions pour optimiser le traitement des données et les communications internes.",
    ]
  };

  // Correspondance entre les services et les catégories de questions
  const serviceToCategoryMap = {
    commercial: "Service Commercial",
    comptabilite: "Service Comptabilité",
    marketing: "Service Marketing",
    informatique: "Service Informatique",
    administration: "Service Administratif"
  };

  useEffect(() => {
    const loadUserDetailsAndQuestions = async () => {
      setLoading(true);
      try {
        const user = getUserDetails();
        if (user && user.service) {
          setUserService(user.service);
          setUserRole(user.role); // Récupérer le rôle de l'utilisateur

          // Si l'utilisateur est admin ou manager, il peut voir toutes les catégories
          if (user.role === 'admin' || user.role === 'manager') {
            setQuestions(questionsByCategory); // Toutes les catégories pour admin/manager
          } else {
            // Sinon, afficher uniquement les questions de son service
            const serviceCategory = serviceToCategoryMap[user.service];
            setQuestions({ [serviceCategory]: questionsByCategory[serviceCategory] });
          }
        } else {
          throw new Error('Utilisateur non authentifié ou service/role non défini');
        }
      } catch (error) {
        console.error('Erreur lors du chargement des détails utilisateur ou des questions :', error);
        toast.error("Erreur lors du chargement des données utilisateur.");
      } finally {
        setLoading(false);
      }
    };

    loadUserDetailsAndQuestions();
  }, []);

  const handleNewChat = async (content) => {
    setLoading(true);
    try {
      const user = getUserDetails();
      if (!user || !user.access_token) {
        throw new Error('User not authenticated');
      }

      const newChatData = {
        role: "user",
        content: content,  // Le contenu dynamique selon la question sélectionnée
      };

      console.log('Envoi de la question:', content);
      const response = await API.post(URLS.CHATGPT.chat.createOrGetChat, newChatData, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });

      const newChatId = response.data.chat_id;
      console.log('Nouvel ID de chat créé:', newChatId);
      navigate(`/chat/${newChatId}`);
    } catch (error) {
      console.error('Erreur lors de la création de la discussion:', error);
      toast.error("Erreur lors de la création d'une nouvelle discussion.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Preloader />; // Affiche un préloader si les questions ou les détails utilisateur sont encore en cours de chargement
  }

  return (
    <div className="quickmessage-container">
      <h1 className='fw-bold'>{userRole === 'admin' || userRole === 'manager' ? 'Toutes les catégories' : `Questions pour le service ${serviceToCategoryMap[userService]}`}</h1>
      
      {Object.keys(questions).map((category) => (
        <div key={category} className="quickmessage-category">
          <h2>{category}</h2>
          {questions[category].map((question, index) => (
            <div key={index} className="quickmessage-card" onClick={() => handleNewChat(question)}>
              <p>{question}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Quickmessage;
